import { mapActions, mapMutations } from 'vuex';
export default {
  methods: {
    ...mapMutations(['updateLogoutPopupStatus']),
    ...mapActions('user', ['logout']),
    logoutAndRedirect() {
      this.updateLogoutPopupStatus(true);
    }
  }
};
